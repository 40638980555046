import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
// Required for Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { RouterOutlet } from '@angular/router';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
// Required for MSAL
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { NavbarComponent } from '@componentes/navbar/navbar.component';
import { TegelModule } from '@scania/tegel-angular-17';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
// Required modules and components for this application
import { NgxPaginationModule } from 'ngx-pagination';
import { AppComponent } from './app.component';
import { LoadingNotifyComponent } from './dashboard/loading-notify/loading-notify.component';
import { snapeInterceptorInterceptor } from './shared/interceptor/snape-interceptor.interceptor';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

// Create an NgModule that contains the routes and MSAL configurations
@NgModule({
  declarations:[
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MsalModule,
    RouterOutlet, 
    NgxPaginationModule,
    TegelModule,    
    NavbarComponent, 
    LoadingNotifyComponent,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: 'e1300e4f-d09f-4551-ab43-7c949a21595e',
          authority:'https://login.microsoftonline.com/3bc062e4-ac9d-4c17-b4dd-3aad637ff1ac',
          redirectUri: environment.redirectUri,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
        },
      }),
      {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
          scopes: ['user.read'],
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          [ environment.urlSnape,  ['api://e1300e4f-d09f-4551-ab43-7c949a21595e/User.Read'],
          ],
        ]),
      }
    )
    
  ],
  providers: [
    provideAnimations(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: snapeInterceptorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }