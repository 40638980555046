import { bootstrapApplication, platformBrowser } from '@angular/platform-browser';
import { defineCustomElements } from '@scania/tegel/loader';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';

if(environment.production){
  enableProdMode();
}

defineCustomElements(window);

platformBrowser().bootstrapModule(AppModule)
  .catch((err) => console.error(err));
