import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { finalize, Observable, tap } from 'rxjs';
import { LoadingService } from './../../dashboard/loading-notify/loading.service';


@Injectable()
export class snapeInterceptorInterceptor implements HttpInterceptor {
  
  srv = inject(LoadingService);
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    try {
      this.srv.show();    
      var retorno =  next.handle(req).pipe(
        finalize( () => {
             this.srv.hide();
        }),
        tap( { 
              error: () => this.srv.hide() 
        })
      );
  
      return retorno;
        
    } catch (error) {
      this.srv.hide();
      return next.handle(req);      
    }
  }


};
