import { Component, OnInit } from '@angular/core';
import { LoginService } from '../app/shared/services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  standalone: false
})
export class AppComponent implements OnInit {
  title = 'Snape';
  constructor(private loginService: LoginService) {}

  ngOnInit() {
    this.loginService.checkTokenAndUserMsal(); // Verifica e armazena o e-mail do usuário
  }
}

